import type { NextPage } from "next";
import { useRouter } from "next/dist/client/router";
import Head from "next/head";
import Link from "next/link";
import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";

import Header from "../components/Landing/Header";
import HeroSection from "../components/Landing/HeroSection";
import PickYesNoSection from "../components/Landing/PickYesNo";
import SocialTrading from "../components/Landing/SocialTrading";
import MonetizeYourSkills from "../components/Landing/MonetizeYourSkills";
import ShareAndDiscussSection from "../components/Landing/ShareAndDiscuss";
import GetStartedSection from "../components/Landing/GetStarted";
import RetailInvestorSection from "../components/Landing/RetailInvestor";
import Footer from "../components/Landing/Footer";

import { ArrowNarrowRightIcon, LockClosedIcon } from "@heroicons/react/outline";
import { useAuth } from "../context/AuthContext";

const Home: NextPage = () => {
  const { isLoadingFetchUser, isLogged } = useAuth();
  const Router = useRouter();
  const [isLoginBefore, setIsLoginBefore] = useState<boolean>(false);

  useEffect(() => {
    const { pathname } = Router;
    if (localStorage?.getItem("auth")) {
      setIsLoginBefore(true);
    }

    // isLoginBefore = localStorage.getItem("auth");
    if (pathname == "/") {
      Router.push("/daily");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   window.addEventListener("scroll", () => {
  //     const revealsElm = document.querySelectorAll(".square");

  //     for (let i = 0; i < revealsElm.length; i++) {
  //       const windowHeight = window.innerHeight;
  //       const revealTop = revealsElm[i].getBoundingClientRect().top;
  //       const revealPoint = 0;
  //       if (revealTop < windowHeight - revealPoint) {
  //         revealsElm[i].classList.add("reveal-active");
  //       } else {
  //         revealsElm[i].classList.remove("reveal-active");
  //       }
  //     }
  //   });
  // }, []);

  if (!isLoginBefore && false)
    // update feedback 21/01/2022 the landing page, we will not use anymore. So they'll go directly to this
    return (
      <>
        <Head>
          {/* Font */}
          <link
            href="https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500;600;700;800;900&display=swap"
            rel="stylesheet"
          />
        </Head>

        <Header />

        <main className="max-w-7xl mx-auto px-6 md:px-8">
          <HeroSection />

          {/* Pick Yes or No  */}
          <PickYesNoSection />

          {/* Social Trading */}
          <SocialTrading />

          {/* security */}
          <Fade bottom distance="120px" duration={600}>
            <div className="bg-brand-light-navy flex items-center justify-between rounded-lg shadow-box px-4 md:px-6 py-3 my-9 lg:my-0">
              <div className="flex items-center text-brand-gray-400 space-x-3 md:space-x-5">
                <LockClosedIcon className="w-5 h-5 flex-shrink-0" />
                <p className="text-xs md:text-base md:leading-4">
                  We encrypt all transactions and store deposits in highly
                  secure non-custodial accounts.
                </p>
              </div>

              <Link href="/">
                <a className="hidden lg:inline-flex items-center text-brand-green-500 space-x-3">
                  <ArrowNarrowRightIcon className="w-6" />
                  <span className="font-medium leading-5">Sign up</span>
                </a>
              </Link>
            </div>
          </Fade>

          {/* Monetize Your Skills */}
          <MonetizeYourSkills />

          {/* Share & Discuss */}
          <ShareAndDiscussSection />

          {/* Ready to get started */}
          <GetStartedSection />

          {/* Build for Retail investors */}
          <RetailInvestorSection />
        </main>

        <Footer />
      </>
    );

  return (
    <>
      <Head>
        <meta
          property="og:image"
          content="https://stockbet.io/MetatagSiteImageV5.jpeg"
        ></meta>
        <meta
          property="twitter:image"
          content="https://stockbet.io/MetatagSiteImageV5.jpeg"
        ></meta>
        <meta property="og:url" content="https://stockbet.io/" />
        <meta property="twitter:url" content="https://stockbet.io/" />
      </Head>
    </>
  );
};

export default Home;

import Fade from "react-reveal/Fade";

const testimonials = [
  {
    name: "Rick Lennie",
    position: "Entrepreneur",
    text: "Hedging has never been simpler. All I have to do is just choose green or red.",
    image: "user-01",
  },
  {
    name: "Leon Ephraim",
    position: "Accounts Executive",
    text: "I used to never hedge because options were just way too complicated for me. StockBet has made hedging so much easier for me.",
    image: "user-04",
  },
  {
    name: "Pasine Straker",
    position: "Legal Counsel",
    text: "Everything is so simple. I'm so glad I don't have to scratch my head trying to understand options contracts. This is so much easier!",
    image: "user-02",
  },
  {
    name: "Dennis Siqueria",
    position: "Actor, Filmmaker",
    text: "Love it, such a simple yet elegant concept. Will keep using it to hedge from now on.",
    image: "user-05",
  },
  {
    name: "Dann Mariota",
    position: "Software Engineer",
    text: "I've finally started hedging more now that everything is so simple to understand.",
    image: "user-03",
  },
  {
    name: "Jadish Jampana",
    position: "Professor",
    text: "Options were too confusing for me. I barely understand how they are priced. Meanwhile, StockBet is simple + straightforward.",
    image: "user-06",
  },
];

const RetailInvestorSection = () => {
  return (
    <div id="testimonials" className="py-11 xl:py-48">
      <div className="text-center">
        <Fade bottom distance="60px" duration={800}>
          <h1 className="text-4xl xl:text-64 font-bold">
            Built for <br className="lg:hidden" /> Retail Investors
          </h1>
        </Fade>
        <Fade bottom distance="70px" duration={800} delay={100}>
          <p className="lg:text-xl xl:text-28 xl:leading-9 mt-3 xl:mt-5">
            {`Hedging shouldn't be so complicated. StockBet makes hedging simple.`}
          </p>
        </Fade>
      </div>
      <Fade bottom distance="70px" duration={800} delay={250}>
        <div className="max-w-6xl col-count mx-auto mt-8 xl:mt-24 lg:px-5">
          {testimonials.map((testimonial, index) => (
            <Testimonial {...testimonial} key={index} />
          ))}
        </div>
      </Fade>
    </div>
  );
};

const Testimonial = ({ name, position, text, image }) => (
  <div className="inline-block bg-brand-light-navy lg:hover:bg-brand-hover-green shadow-box rounded-md transition-colors p-6 pt-8 mb-7 xl:mb-8">
    <div className="flex space-x-4">
      <div className="w-11 h-11 overflow-hidden rounded-full">
        <img
          className="w-full h-full object-cover object-center"
          src={`/investors/${image}.png`}
          alt=""
        />
      </div>
      <div>
        <h1 className="font-medium">{name}</h1>
        <p className="text-brand-gray-400 font-medium lg:leading-5">
          {position}
        </p>
      </div>
    </div>

    <p className="text-sm leading-6 mt-5">{text}</p>
    <div className="flex items-center text-brand-gray-500 space-x-2 mt-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.269"
        height="14.846"
        viewBox="0 0 18.269 14.846"
      >
        <path
          id="icons8-twitter"
          d="M18.21,7.374a7.5,7.5,0,0,1-2.154.591A3.757,3.757,0,0,0,17.7,5.892a7.526,7.526,0,0,1-2.381.908,3.75,3.75,0,0,0-6.386,3.419A10.643,10.643,0,0,1,1.213,6.3a3.756,3.756,0,0,0,1.16,5.006,3.745,3.745,0,0,1-1.7-.47v.047A3.753,3.753,0,0,0,3.68,14.562a3.751,3.751,0,0,1-.987.131,3.611,3.611,0,0,1-.706-.068,3.757,3.757,0,0,0,3.5,2.6,7.514,7.514,0,0,1-4.654,1.6,7.374,7.374,0,0,1-.893-.053,10.59,10.59,0,0,0,5.745,1.684A10.591,10.591,0,0,0,16.351,9.8c0-.162,0-.326-.01-.486A7.619,7.619,0,0,0,18.21,7.374Z"
          transform="translate(0.059 -5.617)"
          fill="currentColor"
        />
      </svg>
      <p className="block text-sm leading-4 pt-1">View Tweet</p>
    </div>
  </div>
);

export default RetailInvestorSection;

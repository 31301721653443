// Potential Return = (Bet Amount * Multiplier - Bet amount)*93.1% + Bet Amount*
// Potential Return = Bet Amount [(1*Multiplier-1)*93.1%+1)
// Potential Return = Bet Amount [0.931(Multiplier-1)+1]
// **Potential Return = Bet Amount (0.931*Multiplier+0.069)**

// Example → $500 Bet with 2x Multiplier
// Potential Return = $500(0.931*2+0.069)
// **Potential Return= $965.5**

export const calculateProfit = (amount: number, multiplier: number) => {
  if (multiplier === 0 || multiplier === 1)
    return Math.round(0.931 * amount * 100) / 100;
  return amount * (0.931 * multiplier + 0.069);
  // return Math.round(amount * (0.931 * multiplier + 0.069));
};
export const calculateMultiplier = (total: number, typeBetTotal: number) => {
  if (typeBetTotal <= 0) return 0;
  return Math.round((total / typeBetTotal) * 100) / 100;
};

export const calculatePercent = (total: number, typeBetTotal: number) => {
  if (typeBetTotal <= 0) return 0;
  const number = Math.round((typeBetTotal / total) * 10000) / 10000;
  return number * 100;
};
export const calculate = (
  total: number,
  greenTotal: number,
  redTotal: number
) => {
  const typeBetTotal = greenTotal + redTotal;
  if (typeBetTotal <= 0) return 0;
  const number = (total / typeBetTotal).toFixed(2);
  return number;
};
